import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { Col, Row, Spin } from 'antd';
import { hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import { useTranslations } from '@veraio/strank';
import { Button, Tag, TAG_TYPE } from 'components/UIExternal';
import { TabPane, Tabs, Icon, DeclineModal, CommentModal } from 'components/ui';
import { CommentsList } from 'components/shared';
import { businessStatus, dealStatus, dealStatusColor, dealStatusInverse, dealRestrictedCategories } from 'enums';
import { useParamsInt } from 'utils';
import useError from 'services/errorHandling/useError';
import { addCommentToDeal, updateDealStatus, getDealById } from 'services';
import { DealBusinessAndFacilitiesTab, DealImagesTab, DealInformationTab, DealStatusHistoryTab } from './tabs';
import { pageWrapper, tabs, messageContainer } from './styles';

const DealDetail = () => {
  const { getText, getDynamicTranslation } = useTranslations();
  const { setError } = useError();
  const dealId = useParamsInt('dealId');
  const history = useHistory();
  const [deal, setDeal] = useState({});
  const [isDeclineModalVisible, setIsDeclineModalVisible] = useState(false);
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const isPendingBusiness = businessStatus.pending === deal.businessStatusId;
  const isAdmin = hasRole(USER_ROLES.DS_ADMIN);
  const isRestrictedCategory = dealRestrictedCategories?.includes(deal?.category);

  useEffect(() => {
    fetchDeal();
  }, [dealId]);

  const fetchDeal = async () => {
    const [res, err] = await getDealById(dealId);
    err ? setError(err) : setDeal(res);
  };

  const handleEditDealStatus = async (status, reason) => {
    const [, err] = await updateDealStatus({ id: dealId, status, reason });
    err ? setError(err) : fetchDeal();
    setIsDeclineModalVisible(false);
  };

  const handleAddCommentToDeal = async (comment) => {
    const [, err] = await addCommentToDeal({ id: dealId, comment });
    if (err) return setError(err);
    fetchDeal();
    setIsCommentModalVisible(false);
  };

  return !isEmpty(deal) ? (
    <div css={pageWrapper} className="inner-content-container">
      <div className="flex deal-name-wrapper">
        <div className="specs-container">
          <Button type="link" onClick={() => history.goBack()} small>
            <Icon iconName="las la-arrow-left" />
          </Button>
          <h2 className="flex flex-center deal-name">{getDynamicTranslation(deal.dealDetails)?.title}</h2>
          <Tag type={dealStatusColor[deal.statusId]} marginRight="1em">
            {getText(dealStatusInverse[deal.statusId])}
          </Tag>
          <Tag type={deal.listed ? TAG_TYPE.GREEN : TAG_TYPE.YELLOW}>
            {deal.listed ? getText('listed') : getText('unlisted')}
          </Tag>
        </div>
        <div className="actions-container">
          <Button
            type="secondary"
            small
            onClick={() =>
              window.open(`${applicationUrls.dealshakerPortal}/deal/preview/${deal.name}`, '_blank').focus()
            }>
            {getText('viewDeal')}
          </Button>
          {(isAdmin || !isRestrictedCategory) && (
            <>
              <Button type="secondary" small onClick={() => setIsCommentModalVisible(true)}>
                {getText('comment')}
              </Button>
              {deal.statusId !== dealStatus.approved && (
                <Button type="secondary" small onClick={() => handleEditDealStatus(dealStatus.approved)}>
                  {getText('approveDeal')}
                </Button>
              )}
              {deal.statusId !== dealStatus.declined && (
                <Button type="danger" small onClick={() => setIsDeclineModalVisible(true)}>
                  {getText('declineDeal')}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      {isPendingBusiness && (
        <Row>
          <Col span={24} lg={18} />
          <Col span={24} lg={6} css={messageContainer}>
            <h3>{getText('businessNotApprovedYet')}</h3>
          </Col>
        </Row>
      )}
      <CommentsList comments={deal?.comments} />
      <Tabs css={tabs}>
        <TabPane destroyInactiveTabPane key={1} tab={getText('dealInformation')}>
          <DealInformationTab
            onEditDeal={fetchDeal}
            deal={deal}
            isAdmin={isAdmin}
            isRestrictedCategory={isRestrictedCategory}
          />
        </TabPane>
        <TabPane destroyInactiveTabPane key={2} tab={getText('businessAndFacilities')}>
          <DealBusinessAndFacilitiesTab deal={deal} />
        </TabPane>
        <TabPane destroyInactiveTabPane key={3} tab={getText('dealImages')}>
        <DealImagesTab media={deal.media} dealId={deal.id} reloadData={fetchDeal}/>        </TabPane>
        <TabPane destroyInactiveTabPane key={4} tab={getText('statusHistory')}>
          <DealStatusHistoryTab deal={deal} />
        </TabPane>
      </Tabs>
      <DeclineModal
        title={getText('deal')}
        isVisible={isDeclineModalVisible}
        onCancel={() => setIsDeclineModalVisible(false)}
        onAccept={(reason) => handleEditDealStatus(dealStatus.declined, reason)}
      />
      <CommentModal
        isVisible={isCommentModalVisible}
        onCancel={() => setIsCommentModalVisible(false)}
        onAccept={handleAddCommentToDeal}
      />
    </div>
  ) : (
    <Spin size="large" />
  );
};

export default DealDetail;
