export const merchantFileType = {
  sizeLimit: 4194304,
  allowedFormat: ['image/jpeg', 'image/png'],
};

export const merchantImageFileType = {
  allowedFormat: ['image/jpeg', 'image/png', 'image/jpg'],
};

export const fileType = {
  pdf: 4,
};
